<template>
  <el-drawer append-to-body :with-header="false" :visible.sync="visible" :show="show" @close="$emit('update:show', false)" size="900px">
    <div class="open-invoice-details">
      <el-tabs v-model="activeName">
        <el-tab-pane label="订单详情" name="first">
          <div class="invoice-middle">
            <div class="middle-average">
              <div class="first-row">
                <span style="font-size: 14px; font-weight: bold">订单信息</span>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">开票方式:</label>
                <div>{{ invoice.scene == 'DMXKP' ? '多明细开票' : invoice.scene == 'KSKP' ? '快速开票' : '' }}</div>
              </div>
              <div class="other-row">
                <label-span label="订单编号：" :span="invoice.sheetCode"></label-span>
              </div>
              <div class="other-row">
                <label style="min-width: 42px">创建时间:</label>
                <el-tooltip effect="dark" :content="invoice.created" placement="top-start">
                  <span style="font-size: 14px">{{ invoice.created }}</span>
                </el-tooltip>
              </div>
              <div class="other-row">
                <label style="min-width: 98px">创建人：</label>
                <el-tooltip effect="dark" :content="invoice.creatorName" placement="top-start">
                  <span style="font-size: 14px">{{ invoice.creatorName }}</span>
                </el-tooltip>
              </div>
              <div class="other-row">
                <label style="min-width: 98px">发票备注：</label>
                <el-tooltip effect="dark" :content="invoice.invoiceRemark" placement="top-start">
                  <span style="font-size: 14px">{{ invoice.invoiceRemark }}</span>
                </el-tooltip>
              </div>
              <div class="other-row">
                <label style="min-width: 98px">备注信息：</label>
                <el-tooltip effect="dark" :content="invoice.remark" placement="top-start">
                  <span style="font-size: 14px">{{ invoice.remark }}</span>
                </el-tooltip>
              </div>
              <div class="other-row">
                <label style="min-width: 98px">消费小票：</label>
                <el-image v-if="invoice.receiptImg" style="width: 100px; height: 100px"  :src="invoice.receiptImg" alt=""></el-image>
                <span style="font-size: 14px" v-else>暂无数据</span>
              </div>
            </div>
            <div class="middle-average">
              <div class="first-row">
                <span>&nbsp;&nbsp;</span>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">发票类型:</label>
                <div>{{ handleValueToLabel('BillingType', invoice.billingType) }}</div>
              </div>
              <div class="other-row">
                <label style="min-width: 42px">状态：</label>
                <div style="font-size: 14px">{{ invoice.invoiceStatus == 'WCL' ? '未处理' : invoice.invoiceStatus == 'YCL' ? '已处理' : invoice.invoiceStatus == 'YZF' ? '未作废' : '--' }}</div>
              </div>
              <div class="other-row">
                <label>有效日期:</label>
                <el-tooltip effect="dark" :content="invoice.expiredTime" placement="top-start">
                  <span style="font-size: 14px">{{ invoice.expiredTime || '--' }}</span>
                </el-tooltip>
              </div>
              <div class="other-row">
                <label style="min-width: 98px">更新时间：</label>
                <el-tooltip effect="dark" :content="invoice.sellerBankAccount" placement="top-start">
                  <span style="font-size: 14px">{{ invoice.sellerBankAccount || '--'}}</span>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="invoice-middle">
            <div class="middle-average">
              <div class="first-row">
                <span style="font-size: 14px; font-weight: bold">购买方</span>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">名称:</label>
                <div>{{ invoice.buyerName || '--' }}</div>
              </div>
              <div class="other-row">
                <label-span label="税号：" :span="invoice.buyerTaxNo || '--'"></label-span>
              </div>
              <div class="other-row">
                <label>地址电话:</label>
                <el-tooltip effect="dark" :content="invoice.buyerAddressTel" placement="top-start">
                  <span style="max-width: 18em">{{ invoice.buyerAddressTel || '--'}}</span>
                </el-tooltip>
              </div>
              <div class="other-row">
                <label style="min-width: 98px">开户行及账号：</label>
                <el-tooltip effect="dark" :content="invoice.buyerBankAccount" placement="top-start">
                  <span style="max-width: 17em">{{ invoice.buyerBankAccount  || '--'}}</span>
                </el-tooltip>
              </div>
            </div>
            <div class="middle-average">
              <div class="first-row">
                <span style="font-size: 14px; font-weight: bold">销售方</span>
              </div>
              <div class="second-row">
                <label style="min-width: 42px">名称:</label>
                <div>{{ invoice.sellerName || '--' }}</div>
              </div>
              <div class="other-row">
                <label-span label="税号：" :span="invoice.sellerTaxNo || '--'"></label-span>
              </div>
              <div class="other-row">
                <label>地址电话:</label>
                <el-tooltip effect="dark" :content="invoice.sellerAddressTel" placement="top-start">
                  <span style="max-width: 18em">{{ invoice.sellerAddressTel || '--'}}</span>
                </el-tooltip>
              </div>
              <div class="other-row">
                <label style="min-width: 98px">开户行及账号：</label>
                <el-tooltip effect="dark" :content="invoice.sellerBankAccount" placement="top-start">
                  <span style="max-width: 17em">{{ invoice.sellerBankAccount || '--'}}</span>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="invoice-bottom">
            <el-row type="flex" justify="space-between">
              <el-col>
                <span>订单信息</span>
              </el-col>
              <el-col align="right">
                <el-checkbox v-model="isShowName">显示税收分类名称</el-checkbox>
                <el-checkbox v-model="isShowCode">显示税收分类编码</el-checkbox>
              </el-col>
            </el-row>

            <div class="bottom-table">
              <el-table :data="tableData" border stripe :span-method="arraySpanMethod" :header-cell-style="handleHeaderCellStyle" max-height="280">
                <el-table-column prop="merchandiseName" fixed width="150" label="货物或应税服务名称" :formatter="fmtTableColumn" />
                <el-table-column prop="specification" label="规格型号" />
                <el-table-column prop="unit" label="单位" />
                <el-table-column prop="quantity" label="数量" />
                <el-table-column prop="unTaxPrice" label="单价" />
                <el-table-column prop="amount" label="金额" />
                <el-table-column prop="taxRate" label="税率" :formatter="fmtTableColumn" />
                <el-table-column prop="taxAmount" label="税额" :formatter="fmtTableColumn" />
                <el-table-column v-if="isShowName" prop="taxClassName" label="分类名称" fixed="right" width="110" />
                <el-table-column v-if="isShowCode" prop="taxClassCode" label="分类编码" fixed="right" width="110" />
              </el-table>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-drawer>
</template>

<script>
import { tableStyle, changeMethods } from '@/util/mixins';

export default {
  mixins: [tableStyle, changeMethods],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    invoice: Object
  },
  data() {
    return {
      isShowName: false,
      isShowCode: false,
      visible: this.show,
      activeName: 'first',
      equBillingMode: []
    };
  },
  created() {},
  watch: {
    show() {
      this.visible = this.show;
    }
  },
  computed: {
    tableData() {
      return this.invoice.items;
    }
  },
  methods: {
    arraySpanMethod({ rowIndex, columnIndex }) {
      const openDetailsLength = this.invoice.items.length;
      if (rowIndex === openDetailsLength - 1) {
        if (columnIndex === 0) {
          return [1, 1];
        } else if (columnIndex === 1) {
          return [1, 4];
        } else {
          return [1, 5];
        }
      }
    },
    isPreferential(val) {
      return val == '免税' || val == '不征税';
    },
    // 格式化税率、税额
    fmtTableColumn(row, column) {
      switch (column.property) {
        case 'taxRate':
          return this.isPreferential(row.taxPreferentialContent) ? row.taxPreferentialContent : row.taxRate;
        case 'taxAmount':
          return this.isPreferential(row.taxPreferentialContent) ? '***' : row.taxAmount;
        case 'merchandiseName':
          return row.taxClassName != null ? '*' + row.taxClassName + '*' + row.merchandiseName : row.merchandiseName;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.open-invoice-details {
  margin: 0 24px;

  ::v-deep .el-tabs__nav {
    margin: 0;
  }

  .invoice-top {
    display: flex;
    margin-top: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid #cccccc;

    .label-span {
      margin-right: 86px;
    }
  }

  .invoice-middle {
    display: flex;
    margin-top: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid #cccccc;

    .middle-average {
      width: 400px;

      .first-row {
        margin-bottom: 16px;

        span {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
        }
      }

      .second-row {
        display: flex;
        margin-bottom: 16px;

        label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 4px;
        }

        div {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          text-align: left;
          margin-right: 20px;
        }
      }

      .other-row {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        margin-right: 20px;

        label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 4px;
        }

        span {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          line-height: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }

  .invoice-bottom {
    margin-top: 32px;
    height: calc(100% - 225px);
    border-bottom: 1px solid #cccccc;
    padding-bottom: 32px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }

    .bottom-table {
      margin-top: 16px;
    }
  }

  .invoice-remark {
    margin-top: 24px;

    .remark-title {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
      margin-bottom: 16px;
    }

    .remark-content {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 24px;
      border-bottom: 1px solid #cccccc;
    }
  }

  .open-invoice-message {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin-bottom: 24px;
    display: flex;

    .one-col {
      width: 400px;

      .second-row {
        display: flex;
        margin-bottom: 16px;

        label {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          margin-right: 4px;
        }

        div {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          text-align: left;
          margin-right: 20px;
        }
      }
    }
  }
}

.el-row {
  margin-bottom: 16px;

  &:first-child {
    margin-top: 32px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .label-span {
    span {
      margin-left: 8px;
    }
  }
}

::v-deep .el-drawer__body {
  overflow: auto;
}
</style>
