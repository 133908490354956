<template>
  <div class="ticket_collec_setting_view">
    <div style="width: 100%; background: #fff">
      <div >
        <el-tabs style="width: 100%" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane style="font-size: 16px" lazy label="快速开票" name="first">
            <div class="opt_box">
              <div style="width: 69%">
                <el-form ref="form" :rules="rules" :model="params" label-width="180px">
                  <el-form-item class="check_set" label="商户名称">
                    <el-select v-model="params.orgId" @change="orgIdChange" placeholder="请选择商户名称">
                      <el-option v-for="item in organizationList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="check_set" label="发票类型">
                    <el-select v-model="params.billingType" placeholder="请选择发票类型">
                      <el-option v-for="item in billingTypes" :key="item.type" :label="item.name" :value="item.type"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="check_set" label="项目名称">
                    <el-select v-model="params.tmplName" @change="tmplNameChange" placeholder="请选择项目名称">
                      <el-option v-for="item in tmplList" :key="item.id" :label="item.tmplName" :value="item.id"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="check_set" label="税率">
                    <el-input :value="taxRate + '%'" disabled></el-input>
                  </el-form-item>
                  <el-form-item class="check_set" prop="sumAmount" label="消费金额">
                    <el-input v-model.number="params.sumAmount" placeholder="请输入消费金额"></el-input>
                  </el-form-item>
                  <el-form-item class="check_set" prop="unit" label="单位">
                    <el-input v-model="params.unit" placeholder="请输入单位" @keyup.enter.native="handleInputConfirm($event, 'unit')" @blur="handleInputConfirm($event, 'unit')"></el-input>
                  </el-form-item>
                  <el-form-item class="check_set" prop="quantity" label="消费数量">
                    <el-input v-model.number="params.quantity" placeholder="请输入数量" @keyup.enter.native="handleInputConfirm($event, 'quantity')" @blur="handleInputConfirm($event, 'quantity')"></el-input>
                  </el-form-item>
                  <el-form-item class="check_set" prop="price" label="消费单价">
                    <el-input v-model.number="params.price" placeholder="请输入单价" @keyup.enter.native="handleInputConfirm($event, 'price')" @blur="handleInputConfirm($event, 'price')"></el-input>
                  </el-form-item>
                  <div class="line"></div>
                  <el-form-item class="check_set" label="">
                    <el-upload action="#" list-type="picture-card" :file-list="fileList" :on-change="_uploadFile" :auto-upload="false">
                      <i slot="default" class="el-icon-plus"><br /><span style="fonst-size: 12px">点击上传消费小票</span></i>
                      <div slot="file" slot-scope="{ file }">
                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                        <span class="el-upload-list__item-actions">
                          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </el-form-item>
                  <el-form-item class="check_set" label="">
                    <span style="margin-right: 20px">展示开户行及账号栏</span>
                    <el-radio-group v-model="params.showCompanyInfoSwitch">
                      <el-radio :label="true">展示</el-radio>
                      <el-radio :label="false">不展示</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item v-if="invoiceRemark" class="check_set" label="发票备注">
                    <el-input v-model="params.invoiceRemark" placeholder="请输入发票备注"></el-input>
                    <el-button @click="invoiceRemark = false" type="text">关闭</el-button>
                  </el-form-item>
                  <el-form-item v-if="dataId" class="check_set" label="关联订单">
                    <el-input v-model="params.dataId" placeholder="请输入关联订单"></el-input>
                    <el-button @click="dataId = false" type="text">关闭</el-button>
                  </el-form-item>
                  <el-form-item v-if="remark" class="check_set" label="添加备注">
                    <el-input v-model="params.remark" placeholder="请输入添加备注"></el-input>
                    <el-button @click="remark = false" type="text">关闭</el-button>
                  </el-form-item>
                  <el-form-item class="check_set" label="">
                    <el-tag v-if="!invoiceRemark" @click="invoiceRemark = true">+ 发票备注</el-tag>
                    <el-tag v-if="!dataId" @click="dataId = true" :style="dataIdStyle">+ 关联订单</el-tag>
                    <el-tag v-if="!remark" @click="remark = true">+ 添加备注</el-tag>
                  </el-form-item>
                  <el-form-item style="margin-bottom: 60px; margin-top: 30px">
                    <el-button @click="reset">清空</el-button>
                    <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="onSubmit('form')">确认</el-button>
                  </el-form-item>
                </el-form>
              </div>
              <div style="width: 31%">
                <electronic-ticket-print
                    ref="electronicTicketPrint"
                    :open-invoice-data="openInvoiceData"
                    :printDisabled="!openInvoice"
                    style="margin-top: 20px"
                >
                  <template v-slot:emptyBox v-if="!openInvoice">
                    <el-empty description="请在左侧输入消费金额"></el-empty>
                  </template>
                  <template v-slot:btnBox>
                    <el-button :disabled="!openInvoice" @click="dialogVisibleJE = true">修改金额</el-button>
                    <el-button :disabled="!openInvoice" @click="dialogVisibleZF = true">作废</el-button>
                    <el-button :disabled="!openInvoice" @click="dialogVisibleYX = true">发送到邮箱</el-button>
                  </template>
                </electronic-ticket-print>

<!--                <div class="consumption_receipt_box">-->
<!--                  <span class="unit_tagS">-->
<!--                    <el-input style="margin: 20px 0; font-size: 16px; color: black" class="input-new-tag" v-if="titleInputVisible" v-model="title" ref="titleAmend" size="small" @keyup.enter.native="handleInputConfirmTitle" @blur="handleInputConfirmTitle"> </el-input>-->
<!--                    <div v-else style="margin: 20px 0; font-size: 16px; color: black; cursor: pointer" @click="handlerTitleAmend">{{ title }}</div>-->
<!--                  </span>-->
<!--                  <div class="line_dashed"></div>-->
<!--                  <div v-if="!openInvoice" style="margin: 50px 0 110px">-->
<!--                    <div style="background-color: #fff">-->
<!--                      <el-empty description="请在左侧输入消费金额"></el-empty>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div v-if="openInvoice" style="margin: 15px 15px 15px 0">-->
<!--                    <el-form ref="form" :model="params" label-width="80px">-->
<!--                      <el-form-item label="商户名称:">-->
<!--                        <span style="margin-left: 20px">{{ openInvoiceData.orgName }}</span>-->
<!--                      </el-form-item>-->
<!--                      <el-form-item label="订单编号:">-->
<!--                        <span style="margin-left: 20px">{{ openInvoiceData.sheetCode }}</span>-->
<!--                      </el-form-item>-->
<!--                      <el-form-item label="项目名称:">-->
<!--                        <span style="margin-left: 20px">{{ tmplName }}</span>-->
<!--                      </el-form-item>-->
<!--                      <el-form-item label="消费金额:">-->
<!--                        <span style="margin-left: 20px">{{ openInvoiceData.sumAmount || '&#45;&#45;' }}</span>-->
<!--                      </el-form-item>-->
<!--                      <el-form-item label="创建时间:">-->
<!--                        <span style="margin-left: 20px">{{ openInvoiceData.created }}</span>-->
<!--                      </el-form-item>-->
<!--                      <el-form-item label="有效日期:">-->
<!--                        <span style="margin-left: 20px">{{ openInvoiceData.expiredTime || '&#45;&#45;' }}</span>-->
<!--                      </el-form-item>-->
<!--                    </el-form>-->
<!--                  </div>-->
<!--                  <div v-if="openInvoice" class="line_dashed"></div>-->
<!--                  <div v-if="openInvoice" style="margin: 15px 0; font-size: 16px; color: black">-->
<!--                    <div style="text-align: center">扫码申请开票</div>-->
<!--                    <div style="margin: 15px 0">-->
<!--                      <img style="width:150px,height:150px" :src="openInvoiceData.qrcodeImg" alt="" />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="line_dashed"></div>-->
<!--                  <div class="unit_tag1" style="display: flex; justify-content: center">-->
<!--                    <el-input style="margin: 20px 0 6px; text-align: center; width: 70%" class="input-new-tag" v-if="titleAdditionalVisible" v-model="Additional" ref="additionalAmend" size="small" @keyup.enter.native="handleInputConfirmAdditional" @blur="handleInputConfirmAdditional"> </el-input>-->
<!--                    <div v-else style="margin: 20px 0 6px; text-align: center; width: 70%; line-height: 24px; cursor: pointer" @click="handlerAdditionalAmend">{{ Additional }}</div>-->
<!--                  </div>-->

<!--                  <div style="margin: 6px 0 20px">客服电话: 4006080017</div>-->
<!--                </div>-->
<!--                <div style="display: flex; justify-content: space-between; margin: 20px 20px 0px 20px">-->
<!--                  <el-button :disabled="!openInvoice" @click="dialogVisibleJE = true">修改金额</el-button>-->
<!--                  <el-button :disabled="!openInvoice" @click="dialogVisibleZF = true">作废</el-button>-->
<!--                  <el-button :disabled="!openInvoice" @click="dialogVisibleYX = true">发送到邮箱</el-button>-->
<!--                  <el-button :disabled="!openInvoice" type="primary" @click="print">打印</el-button>-->
<!--                </div>-->
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane style="font-size: 16px" lazy label="扫码订单" name="second">
            <scanQRcodesList></scanQRcodesList>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <el-dialog title="修改金额" :visible.sync="dialogVisibleJE" width="400px">
      <el-input style="width: 360px; margin-left: 20px; margin-bottom: 30px" placeholder="请输入修改金额" v-model="sumAmount"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleJE = false">取 消</el-button>
        <el-button type="primary" @click="amendAffirm">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="dialogVisibleZF" width="400px">
      <div style="margin-left: 20px; margin-bottom: 30px; margin-right: 30px; font-size: 16px">是否作废该发票申请单？作废后，该发票申请单无法再开具发票。</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleZF = false">取 消</el-button>
        <el-button type="primary" @click="affirmZF">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="发送至邮箱" :visible.sync="dialogVisibleYX" width="400px">
      <div style="width: 360px; font-size: 14px; color: #e0a025; margin-left: 20px; margin-bottom: 20px">电子小票将以邮件附件的形式发送至指定邮箱</div>
      <el-input style="width: 360px; margin-left: 20px; margin-bottom: 30px" placeholder="请输入邮箱地址" v-model="receiveEmail"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleYX = false">取 消</el-button>
        <el-button type="primary" @click="affirmYX">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import scanQRcodesList from './scanQRcodesList.vue';
import { getLastInfo, findOrganization, getTmpl, uploadFile, quickBillCreate, updateQuickSumAmount, quickInvoiceModify, emailSend, getTicketNetworkPrint, getBillConfig, billTypeList } from '@/service/quick-invoice';
import ElectronicTicketPrint from "@/views/invoice-open/quick-invoice/electronicTicketPrint.vue";
export default {
  components: {
    scanQRcodesList,
    ElectronicTicketPrint
  },
  data() {
    return {
      params: {
        orgId: '',
        billingType: '',
        tmplName: '',
        sumAmount: '',
        unit: '', //单位
        quantity: '', //数量
        price: '', //单价
        showCompanyInfoSwitch: '', //展示开户行及账号栏
        invoiceRemark: '', //发票备注
        dataId: '', //关联订单
        remark: '', //添加备注
        items: []
      },
      activeName: 'first',
      invoiceRemark: false, //发票备注
      dataId: false, //关联订单
      remark: false, //添加备注
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      merchandiseId: '',
      organizationList: [], // 组织列表
      billingTypes: [
        { name: '数电普票', type: 'QDPP' },
        { name: '数电专票', type: 'QDZP' },
        { name: '增值税电子普通发票', type: 'Dzpp' },
        { name: '增值税电子专用发票', type: 'DZZP' },
        { name: '纸质普票', type: 'Zzpp' },
        { name: '纸质专票', type: 'Zzzp' },
        { name: '收购发票(电子)', type: 'SGDP' },
        { name: '收购发票(纸质)', type: 'SGZP' },
      ], // 发票类型
      placeholderValue: '',
      tmplList: [],
      file: {},
      fileName: '',
      fileList: [],
      openInvoice: false,
      openInvoiceData: {},
      sumAmount: '',
      dialogVisibleJE: false,
      dialogVisibleZF: false,
      dialogVisibleYX: false,
      receiveEmail: '',
      taxRate: '',
      fullscreenLoading: false,
      titleInputVisible: false,
      titleAdditionalVisible: false,
      receiptRequiredSwitch: false,
      title: '金财数智发票服务',
      Additional: '微信搜索关注“金财数智”，接收、下载发票，请在有效期内扫码申请发票，过期无效',
      rules: {
        sumAmount: [
          { required: true, message: '金额不能为空' },
          { type: 'number', message: '金额必须为数字值' }
        ]
      }
    };
  },
  computed: {
    dataIdStyle() {
      return this.invoiceRemark ? 'margin-left: 0 20px' : 'margin:0 20px';
    },
    tmplName() {
      let i = this.tmplList.find((item) => item.id == this.openInvoiceData.tmplName);
      return i.tmplName;
    }
  },
  watch: {
    'params.sumAmount': function (val) {
      if (this.params.quantity) {
        this.params.price = Math.floor(Number(val / this.params.quantity) * 100) / 100;
        return;
      }
      if (this.params.price) {
        this.params.quantity = Math.floor(Number(val / this.params.price) * 100) / 100;
        return;
      }
    },
    'params.tmplName': {
      handler(val) {
        setTimeout(() => {
          let item = this.tmplList.filter((item) => item.id == val);
          this.taxRate = item[0].items[0].taxRate * 100;
        }, 1000);
      }
    }
  },
  async created() {
    await this._getTmpl();
    this.params.orgId = JSON.parse(localStorage.getItem('orgId') || '');
    await this._findOrganization();
    await this._getBillConfig();
    await this._getLastInfo();
    await this._billTypeList();
  },
  methods: {
    handlerTitleAmend() {
      // this.titleInputVisible = true;
      // this.$nextTick((_) => {
      //   this.$refs.titleAmend.$refs.input.focus();
      // });
    },
    handleInputConfirmTitle() {
      this.titleInputVisible = false;
    },
    async _billTypeList() {
      // 发票类型
      const invoiceTypeParse = (type) => {
        switch (type) {
          case 'QDPP':
            return '数电普票';
          case 'QDZP':
            return '数电专票';
          case 'Dzpp':
            return '增值税电子普通发票';
          case 'DZZP':
            return '增值税电子专用发票';
          default:
          case 'Zzpp':
            return '纸质普票';
          case 'Zzzp':
            return '纸质专票';
          case 'SGDP':
            return '收购发票(电子)';
          case 'SGZP':
            return '收购发票(纸质)';
            break;
        }
      };
      const orderBy = 'field(billing_type,1,6,7,8)';
      const res = await billTypeList({ orgId: this.params.orgId, scene: 'LPSM', orderBy });
      if (res.success) {
        this.billingTypes = res.data.filter((item) => {
          item.name = invoiceTypeParse(item.billingType);
          item.type = item.billingType;
          return item.status;
        });
        console.log(this.billingTypes, 'res');
      }
    },
    async _getBillConfig() {
      let { success, data } = await getBillConfig(`${this.params.orgId}?bizType=KSKP`);
      if (success) {
        this.params.billingType = data.defaultBillingType;
        this.receiptRequiredSwitch = data.receiptRequiredSwitch;
        this.params.tmplName = data.salTmpl.tmplName;
        this.params.taxRate = data.taxRate;
        this.params.items = data?.salTmpl?.items || [];
        this.params.quickPrintType = data.quickPrintType;
        this.params.sumAmount = '';
        this.merchandiseId = data.merchandiseId;
        if(this.tmplList){
          let i = this.tmplList.find((item) => item.tmplName == this.params.tmplName);
          if (this.params.tmplName) {
            this.params.tmplName = i.id;
          }
          this.taxRate = i.items[0].taxRate * 100;
        }
      }
    },
    handlerAdditionalAmend() {
      // this.titleAdditionalVisible = true;
      // this.$nextTick((_) => {
      //   this.$refs.additionalAmend.$refs.input.focus();
      // });
    },
    handleInputConfirmAdditional() {
      this.titleAdditionalVisible = false;
    },
    async onSubmit(formName) {
      if (this.receiptRequiredSwitch) {
        if (this.fileList.length == 0) {
          this.toast('请上传消费小票', 'warning');
          return;
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    async submit() {
      this.params.taxRate = this.taxRate / 100;
      let formData = new FormData();
      formData.append('file', this.file);
      this.fullscreenLoading = true;
      if (this.fileList.length) {
        let { success, data } = await uploadFile(formData);
        if (success) {
          this.params.receiptId = data;
        }
      }
      this.params.items[0] = { unit: '', merchandiseId: '', sumAmount: '', price: '', quantity: '' };
      this.params.items[0].unit = this.params.unit;
      this.params.items[0].price = this.params.price;
      this.params.items[0].quantity = this.params.quantity;
      this.params.items[0].sumAmount = this.params.sumAmount;
      this.params.items[0].merchandiseId = this.merchandiseId;
      this._quickBillCreate();
    },
    async _quickBillCreate() {
      const { success, data } = await quickBillCreate({ ...this.params, sceneType: 'KSKP' });
      this.fullscreenLoading = false;
      if (success) {
        this.openInvoice = true;
        this.openInvoiceData = data;
      }
    },
    reset() {
      this.params = {
        orgId: '',
        billingType: '',
        tmplName: '',
        sumAmount: '',
        unit: '暂无', //单位
        quantity: '暂无', //数量
        price: '暂无', //单价
        showCompanyInfoSwitch: true, //展示开户行及账号栏
        invoiceRemark: '', //发票备注
        dataId: '', //关联订单
        remark: '' //添加备注
      };
      this.invoiceRemark = false; //发票备注
      this.dataId = false; //关联订单
      this.remark = false; //添加备注
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 组织列表
    async _findOrganization() {
      const { success, data = [] } = await findOrganization();
      if (success) {
        this.organizationList = data;
      }
    },
    getLocalStorage() {
      this.$set(this.params, 'quantity', localStorage.getItem('quickBillQuantity') || '');
      this.$set(this.params, 'price', localStorage.getItem('quickBillPrice') || '');
      this.$set(this.params, 'unit', localStorage.getItem('quickBillUnit') || '');
      console.log(this.params.price, this.params.quantity, this.params.unit, 'unit');
    },
    // 获取最后一次的发票信息
    async _getLastInfo() {
      const {
        success,
        data: { sellerBankAccount = '', showCompanyInfoSwitch = false }
      } = await getLastInfo(this.params.orgId);
      if (success) {
        this.params.showCompanyInfoSwitch = sellerBankAccount ? showCompanyInfoSwitch : false;
        this.params.sellerBankAccount = sellerBankAccount;
        this.$set(this.params, 'showCompanyInfoSwitch', this.params.showCompanyInfoSwitch);
      }
      this.getLocalStorage();
    },
    // 输入完成
    handleInputConfirm(e, type) {
      // 数量
      if (type == 'quantity') {
        localStorage.setItem('quickBillQuantity', this.params.quantity);
        if (this.params.quantity) {
          if (this.params.sumAmount) {
            this.params.price = Math.floor((this.params.sumAmount / this.params.quantity) * 100) / 100;
            this.placeholderValue = '';
            return;
          }
          if (this.params.price && !this.params.sumAmount) {
            this.params.sumAmount = Math.floor(this.params.price * this.params.quantity * 100) / 100;
            this.placeholderValue = '';
            return;
          }
        } else {
          if (this.params.sumAmount && this.params.price) {
            this.params.quantity = Math.floor((this.params.sumAmount / this.params.price) * 100) / 100;
            this.placeholderValue = '';
            return;
          }
        }
      }
      // 单价
      if (type == 'price') {
        localStorage.setItem('quickBillPrice', this.params.price);
        if (this.params.price) {
          if (this.params.sumAmount) {
            this.params.quantity = Math.floor((this.params.sumAmount / this.params.price) * 100) / 100;
            this.placeholderValue = '';
            return;
          }
          if (this.params.quantity && !this.params.sumAmount) {
            this.params.sumAmount = Math.floor(this.params.price * this.params.quantity * 100) / 100;
            this.placeholderValue = '';
            return;
          }
        } else {
          if (this.params.sumAmount && this.params.quantity) {
            this.params.price = Math.floor((this.params.sumAmount / this.params.quantity) * 100) / 100;
            this.placeholderValue = '';
            return;
          }
        }
      }
      if (type == 'unit') {
        localStorage.setItem('quickBillUnit', this.params.unit);
      }
      this.placeholderValue = '';
    },
    async _getTmpl() {
      let params = {
        page: 1,
        size: 20
      };
      const { data, success } = await getTmpl(params);
      if (success) this.tmplList = data.records;
    },
    /* 提交上传 */
    _uploadFile(file) {
      this.fileList = [{ url: file.url }];
      this.fileName = file.name;
      this.file = file.raw;
      document.querySelector('.el-upload.el-upload--picture-card').style.display = 'none';
    },
    // 移除
    handleRemove() {
      this.fileName = '';
      this.fileList = [];
      document.querySelector('.el-upload.el-upload--picture-card').style.display = '';
    },
    amendAffirm() {
      if (!this.sumAmount || this.sumAmount <= 0) {
        return this.toast('开票金额不能为空,且不能为0', 'warning');
      }
      const amountReg = /^\d+(\.\d{1,2})?$/;
      if (!amountReg.test(this.sumAmount)) {
        return this.toast('金额格式错误', 'warning');
      }
      updateQuickSumAmount({ id: this.openInvoiceData.id, sumAmount: this.sumAmount }).then((res) => {
        if (res.success) {
          this.dialogVisibleJE = false;
          this.toast('修改成功', 'success');
          this.openInvoiceData.sumAmount = this.sumAmount;
          this.sumAmount = '';
          return;
        }
      });
    },
    cancellation() {},
    async affirmZF() {
      const { success } = await quickInvoiceModify({ id: this.openInvoiceData.id, disposeStatus: 'YZF' });
      if (success) {
        this.toast('作废成功', 'success');
        this.dialogVisibleZF = false;
      }
    },
    async affirmYX() {
      if (!this.receiveEmail) {
        return this.toast('邮箱不能为空', 'warning');
      }
      const emailReg = /[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
      if (!emailReg.test(this.receiveEmail)) {
        return this.toast('邮箱格式错误', 'warning');
      }
      const { success } = await emailSend({ requestId: this.openInvoiceData.id, receiveEmail: this.receiveEmail });
      if (success) {
        this.toast('发送成功', 'success');
        this.dialogVisibleYX = false;
      }
    },
    async print() {
      let { success } = await getTicketNetworkPrint({ id: this.openInvoiceData.id });
      if (success) {
        this.toast('打印成功', 'success');
      }
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    tmplNameChange(data) {
      let i = this.tmplList.find((item) => item.id == data);
      this.merchandiseId = i.items[0].merchandiseId;
    },
    orgIdChange(data) {
      this.params.orgId = data;
      this._getBillConfig();
      this._billTypeList()
    }
  }
};
</script>

<style scoped lang="scss">
.ticket_collec_setting_view {
  // background: #fff;
  height: 100%;
  min-height: 100vh;
  display: flex;
  box-sizing: border-box;
  .top_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    // border-bottom: 1px solid #e9e9e9;
    // box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);
    padding: 5px 0px;
    .tit {
      border-left: 2px solid #999;
      padding-left: 10px;
      font-size: 16px;
      color: #333;
    }
  }
  .opt_box {
    padding: 0 24px;
    font-size: 14px;
    display: flex;
    color: #333;
    ::v-deep .el-form-item__content {
      height: 100%;
    }
    .check_set {
      // font-size: 16px;
      margin: 20px 0;
    }
    .opt_item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .tip {
        margin-left: 26px;
        color: #999;
      }
    }
    ::v-deep .el-input__inner {
      width: 550px;
    }
    ::v-deep .el-date-editor.el-input,
    .el-date-editor.el-input__inner,
    .el-input {
      width: 550px;
    }
    ::v-deep .el-select > .el-input {
      width: 100%;
    }
  }
  .consumption_receipt_box {
    margin: 20px;
    border: 1px solid #e9e9e9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #909399;
  }
  .line {
    border-bottom: 1px solid #d4d3d1;
    width: 90%;
    height: 1px;
    // border-style: dashed ;
  }
  .line_dashed {
    border-bottom: 2px dashed #d4d3d1;
    width: 90%;
    height: 1px;
    // border-style: dashed ;
  }
  ::v-deep .el-upload--picture-card {
    width: 550px;
    span {
      font-size: 14px;
    }
  }
  .unit_tag {
    margin-right: 20px;
    ::v-deep .el-input__inner {
      width: 100px;
    }
  }
  .unit_tagS {
    margin-right: 20px;
    ::v-deep .el-input__inner {
      width: 180px;
      margin-left: -20px;
    }
  }
  .unit_tag1 {
    margin-right: 20px;
    ::v-deep .el-input__inner {
      width: 180px;
      margin-left: -32px;
    }
  }
  ::v-deep .el-dialog .el-dialog__body {
    padding: 20px 0 0 0;
    ::v-deep .el-input {
      width: 300px;
    }
  }
  ::v-deep .el-dialog .el-dialog__body {
    text-align: left;
  }
  ::v-deep .el-form-item__error {
    top: 30px;
  }
}
</style>

<style lang="scss">
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px !important;
  vertical-align: bottom;
}
.el-tabs__item {
  font-size: 14px;
}
.check_set {
  .el-upload--picture-card i {
    margin-top: 42px;
  }
}
</style>
