<template>
  <div class="electronicTicketPrint">
    <div style="width: 280px; margin: 0px 20px 0 20px; border: 1px solid #e9e9e9; padding: 0 40px">
      <div  id="printId" style="width: 270px;">
        <div style="width: 100%; text-align: center; line-height: 50px; font-size: 16px; color: black">远航财税发票服务</div>

        <div style="border-bottom: 1px dashed #000000;width: 90%;height: 1px; margin-left: 5%;"></div>
        <slot name="emptyBox"></slot>
        <div style="width: 100%" v-if="!printDisabled">
          <div style="margin: 15px 0 15px 0; width: 100%">
            <div style="width: 100%;display: flex;justify-content: start;margin-bottom: 15px;">
            <span style="width: 80px;line-height: 18px;font-size: 14px;color: #000000;margin-left: 10px;"
            >商户名称:</span>
              <span class="conCellContent" style="width: calc(100% - 80px);line-height: 18px;font-size: 14px;color: #000000;display: -webkit-box;word-break: break-all;text-overflow: ellipsis;overflow: hidden;-webkit-box-orient: vertical;-webkit-line-clamp: 5;"
              >{{ openInvoiceData.orgName }}</span>
            </div>
            <div style="width: 100%;display: flex;justify-content: start;margin-bottom: 15px;">
            <span style="width: 80px;line-height: 18px;font-size: 14px;color: #000000;margin-left: 10px;"
            >订单编号:</span>
              <span class="conCellContent" style="width: calc(100% - 80px);line-height: 18px;font-size: 14px;color: #000000;display: -webkit-box;word-break: break-all;text-overflow: ellipsis;overflow: hidden;-webkit-box-orient: vertical;-webkit-line-clamp: 5;"
              >{{ openInvoiceData.sheetCode }}</span>
            </div>
            <div style="width: 100%;display: flex;justify-content: start;margin-bottom: 15px;">
            <span style="width: 80px;line-height: 18px;font-size: 14px;color: #000000;margin-left: 10px;"
            >项目名称:</span>
              <span class="conCellContent" style="width: calc(100% - 80px);line-height: 18px;font-size: 14px;color: #000000;display: -webkit-box;word-break: break-all;text-overflow: ellipsis;overflow: hidden;-webkit-box-orient: vertical;-webkit-line-clamp: 5;"
              >{{ openInvoiceData.tmplName || '--' }}</span>
            </div>
            <div style="width: 100%;display: flex;justify-content: start;margin-bottom: 15px;">
            <span style="width: 80px;line-height: 18px;font-size: 14px;color: #000000;margin-left: 10px;"
            >消费金额:</span>
              <span class="conCellContent" style="width: calc(100% - 80px);line-height: 18px;font-size: 14px;color: #000000;display: -webkit-box;word-break: break-all;text-overflow: ellipsis;overflow: hidden;-webkit-box-orient: vertical;-webkit-line-clamp: 5;"
              >{{ openInvoiceData.sumAmount }}</span>
            </div>
            <div style="width: 100%;display: flex;justify-content: start;margin-bottom: 15px;">
            <span style="width: 80px;line-height: 18px;font-size: 14px;color: #000000;margin-left: 10px;"
            >创建时间:</span>
              <span class="conCellContent" style="width: calc(100% - 80px);line-height: 18px;font-size: 14px;color: #000000;display: -webkit-box;word-break: break-all;text-overflow: ellipsis;overflow: hidden;-webkit-box-orient: vertical;-webkit-line-clamp: 5;"
              >{{ openInvoiceData.created }}</span>
            </div>
            <div style="width: 100%;display: flex;justify-content: start;margin-bottom: 15px;">
            <span style="width: 80px;line-height: 18px;font-size: 14px;color: #000000;margin-left: 10px;"
            >有效日期:</span>
              <span class="conCellContent" style="width: calc(100% - 80px);line-height: 18px;font-size: 14px;color: #000000;display: -webkit-box;word-break: break-all;text-overflow: ellipsis;overflow: hidden;-webkit-box-orient: vertical;-webkit-line-clamp: 5;"
              >{{ openInvoiceData.expiredTime || '--' }}</span>
            </div>
          </div>
          <div style="border-bottom: 1px dashed #000000;width: 80%;height: 1px; margin-left: 10%;"></div>
          <div style="margin: 15px 0; font-size: 13px; color: black; text-align:center">
            <div style="text-align: center;">扫码申请开票</div>
            <div style="margin: 15px 0; ">
              <img v-if="openInvoiceData.qrcodeImg" style="width:180px;height:180px" :src="openInvoiceData.qrcodeImg" alt="" />
              <!-- <span v-else>暂无数据</span> -->
            </div>
          </div>
        </div>

        <div style="border-bottom: 1px dashed #000000;width: 80%;height: 1px; margin-left: 10%;"></div>

        <div style="width: 100%; line-height: 20px; text-align: center; margin-top: 10px; font-size: 12px">微信搜索关注“金财数智”，接收、下载发票</div>
        <div style="width: 100%; line-height: 20px; text-align: center; margin-top: 5px; font-size: 12px">请在有效期内扫码申请发票，过期无效</div>
        <div style="width: 100%; line-height: 20px; text-align: center; margin-top: 10px; margin-bottom: 30px; font-size: 12px">客服电话: 4006080017</div>
      </div>
    </div>

    <div style="padding-bottom: 20px; margin-top: 10px; text-align: center">
      <slot name="btnBox"></slot>
      <el-button type="primary" @click="printClick" v-print="print" :disabled="printDisabled">打印</el-button>
    </div>
  </div>
</template>

<script>
import print from 'vue-print-nb'
export default {
  name: "electronicTicketPrint",
  directives: {
    print
  },
  data() {
    return {
      print: {
        id: 'printId',
        popTitle: '', // 打印配置页上方的标题
        extraHead: '', // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
        preview: false, // 是否启动预览模式，默认是false
        previewTitle: '', // 打印预览的标题
        previewPrintBtnLabel: '预览结束，点击开始打印', // 打印预览的标题下方的按钮文本，点击可进入打印
        zIndex: 99999999999920002, // 预览窗口的z-index，默认是20002，最好比默认值更高
        previewBeforeOpenCallback() { //正在加载预览窗口

        }, // 预览窗口打开之前的callback
        previewOpenCallback() { //已经加载完预览窗口，预览打开了
        }, // 预览窗口打开时的callback
        beforeOpenCallback() { //开始打印之前
        }, // 开始打印之前的callback
        openCallback() { //执行打印了
        }, // 调用打印时的callback
        closeCallback() { //关闭了打印工具
        }, // 关闭打印的callback(无法区分确认or取消)
        clickMounted() { //点击v-print绑定的按钮了
        },
        // url: 'http://localhost:8080/', // 打印指定的URL，确保同源策略相同
        // asyncUrl (reslove) {
        // setTimeout(() => {
        // reslove('http://localhost:8080/')
        // }, 2000)
        // },
        standard: '',
        extraCss: ""
      }
    }
  },
  props: {
    openInvoiceData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    printDisabled: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    // 发送到邮箱
    sendEmeal() {
      this.$emit("closeDialog")
    },
    // 打印
    printClick() {
    },
  }
}
</script>

<style scoped lang="scss">
.electronicTicketPrint {
  .consumption_receipt_box {
    //width: calc(100% - 40px);
    //margin: 0px 20px 20px 20px;
    //border: 1px solid #e9e9e9;
    //display: flex;
    //flex-direction: column;
    //align-items: center;
    //justify-content: center;
    //font-size: 12px;
    //color: #909399;
    //::v-deep .el-dialog__body {
    //  margin-top: 20px;
    //}
  }
  .line {
    border-bottom: 1px solid #d4d3d1;
    width: 90%;
    height: 1px;
  }
  .line_dashed {
    border-bottom: 2px dashed #d4d3d1;
    width: 90%;
    height: 1px;
  // border-style: dashed ;
  }
  .conCell {
    width: calc(100% - 10px);
    display: flex;
    justify-content: start;
    margin-bottom: 15px;
    .conCellTitle {
      width: 80px;
      line-height: 18px;
      font-size: 14px;
      font-weight: bold;
      color: #666666;
      margin-left: 10px;
    }
    .conCellContent {
      width: calc(100% - 80px);
      line-height: 18px;
      font-size: 14px;
      color: #3c3c3c;

      display: -webkit-box;
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
    }
  }
}
</style>
