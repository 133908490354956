import { get, post, download } from './index.js';

// 查询自定义开票顺序组织名
export const findOrganization = () => {
  return get('/organization/findOrganization').then(res => res)
}

// 获取最后一次创建开票码勾选状态
export const getLastInfo = (id) => {
  return get(`/store/request/last-info/${id}`).then((res) => res);
};
//开票设置、默认配置
// export const getBillConfig = (id) => request(`/sal/config/default/tmpl/${id}`, '', 'get');
export const getBillConfig = (id) => {
  return get(`/sal/config/default/tmpl/${id}`).then((res) => res);
};

// 模板列表
export const getTmpl = (data) => {
  return get('/sal/tmpl/list', data).then((res) => res);
};

//文件上传
export const uploadFile = (data) => {
  return post('/store/request/uploadFile', data).then((res) => res);
};

// 创建开票码
export const quickBillCreate = (data) => {
  return post('/store/request/quick/bill/create', data).then((res) => res);
};
// 修改金额
// export const updateQuickSumAmount = (data) => request('/store/request/modify', data, 'post');
export const updateQuickSumAmount = (data) => {
  return post('/store/request/modify', data).then((res) => res);
};

// 快速开票作废
export const quickInvoiceModify = (data) => {
  return post('/store/request/modify', data).then((res) => res);
};

// 发送到邮箱
export const emailSend = (data) => {
  return post('/store/request/qrcode/email/send', data).then((res) => res);
};

// 开票申请列表
export const invoiceApplyList = (data) => {
  return get('/store/request/page', data).then((res) => res);
};

// 网口打印
export const getTicketNetworkPrint = (data) => {
  return post('/store/request/small/ticket/print', data).then((res) => res);
};

// 支持的发票种类
// export const billTypeList = (data) => request('/sal/bill/type/list', data, 'get');
export const billTypeList = (data) => {
  return get('/sal/bill/type/list',data).then(res => res);
}
