<template>
  <div class="p-wrap">
    <div class="p-header">
      <p-select-vue :limit="2" :model="selectForm" position="right" :invoiceApplyList-btn-auth="'RP-RP-CX'" :reset-btn-auth="'RP-RP-CZ'" class="p-select" label-width="70px" @reset="reset" @select="invoiceApplyList">
        <el-form-item slot="1" label="订单编号">
          <el-input v-model="selectForm.sheetCode" placeholder="请输入订单编号" type="text"></el-input>
        </el-form-item>
        <el-form-item slot="1" label="创建日期">
          <el-input v-model="selectForm.created" placeholder="请输入创建日期" type="text"></el-input>
        </el-form-item>
        <el-form-item slot="1" label="状态">
          <el-select v-model="selectForm.disposeStatus" placeholder="">
            <el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item slot="2" label="发票备注" prop="loginState">
          <el-input v-model="selectForm.invoiceRemark" placeholder="请输入发票备注" type="text"></el-input>
        </el-form-item>
        <el-form-item slot="2" label="备注信息" prop="processId">
          <el-input v-model="selectForm.remark" placeholder="请输入备注信息" type="text"></el-input>
        </el-form-item>
        <el-form-item slot="2" label="" prop="processId">
          <div style="width: 200px"></div>
        </el-form-item>
      </p-select-vue>
    </div>
    <div class="p-content">
      <el-table ref="recordsTable" v-loading="recordsLoading" :data="records" :header-cell-style="handleHeaderCellStyle" border current-row-key="id" lazy row-key="id" stripe style="width: 100%" @selection-change="handleSelection">
        <el-table-column fixed="left" type="selection" width="50" />
        <el-table-column align="center" label="序号" type="index" width="50">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="开票方式" prop="scene" :formatter="fmtTableColumn"></el-table-column>
        <el-table-column label="订单编号" prop="sheetCode" width="250px"></el-table-column>
        <el-table-column label="发票类型" prop="billingType" :formatter="fmtTableColumn" width="140px"></el-table-column>
        <el-table-column label="销方名称" prop="sellerName" width="140px"> </el-table-column>
        <el-table-column label="项目名称" prop="tmplName"></el-table-column>
        <el-table-column label="消费金额" prop="sumAmount" width="150"> </el-table-column>
        <el-table-column label="创建日期" prop="created" width="150"> </el-table-column>
        <el-table-column label="有效日期" prop="expiredTime" width="200px"></el-table-column>
        <el-table-column label="发票备注" prop="invoiceRemark" width="200px"></el-table-column>
        <el-table-column label="备注信息" prop="remark" width="200px"></el-table-column>
        <el-table-column label="状态" prop="invoiceStatus" :formatter="fmtTableColumn" width="200px"></el-table-column>
        <el-table-column fixed="right" label="操作" prop="operate" width="180">
          <template slot-scope="scope">
            <div class="operate-button">
              <el-button size="medium" type="text" @click="handelrQrCode(scope.row)">二维码</el-button>
              <el-button size="medium" type="text" @click="details(scope.row)">详情</el-button>
              <el-dropdown @command="handleTableItemMenu($event, scope.row)" style="margin-top: 1px; margin-left: 10px">
                <span type="primary" style="color: #409eff">更多</span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="1">修改金额</el-dropdown-item>
                  <el-dropdown-item :command="2">发送邮箱</el-dropdown-item>
                  <el-dropdown-item :command="3">作废</el-dropdown-item>
                  <!-- <el-dropdown-item :command="4">删除</el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
              <!-- <el-button size="medium" type="text" @click="confirmDel(scope.row)">删除</el-button> -->
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination :current-page.sync="paging.current" :page-size="paging.size" :page-sizes="pageSizes" :total="paging.total" background class="p-page" layout="total, sizes, prev, pager, next" @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
    </div>

    <el-dialog title="修改金额" :visible.sync="dialogVisibleJE" width="400px">
      <el-input style="width: 360px; margin-left: 20px; margin-bottom: 30px" placeholder="请输入修改金额" v-model="sumAmount"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleJE = false">取 消</el-button>
        <el-button type="primary" @click="amendAffirm">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="dialogVisibleZF" width="400px">
      <div style="margin-left: 20px; margin-bottom: 30px; margin-right: 30px; font-size: 16px">是否作废该发票申请单？作废后，该发票申请单无法再开具发票。</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleZF = false">取 消</el-button>
        <el-button type="primary" @click="affirmZF">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="发送至邮箱" :visible.sync="dialogVisibleYX" width="400px">
      <div style="width: 360px;font-size:14px;color:#e0a025; margin-left: 20px; margin-bottom: 20px">电子小票将以邮件附件的形式发送至指定邮箱</div>
      <el-input style="width: 360px; margin-left: 20px; margin-bottom: 30px" placeholder="请输入邮箱地址" v-model="receiveEmail"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleYX = false">取 消</el-button>
        <el-button type="primary" @click="affirmYX">确 定</el-button>
      </span>
    </el-dialog>

    <div class="electronic_receipt">
      <el-dialog title="电子小票" :visible.sync="dialogVisibleYL" width="400px">
        <electronic-ticket-print
            ref="electronicTicketPrint"
          :open-invoice-data="openInvoiceData"
        >
          <template v-slot:btnBox>
            <el-button type="primary" @click="dialogVisibleYX = true">发送到邮箱</el-button>
          </template>
        </electronic-ticket-print>

<!--        <div class="consumption_receipt_box">-->
<!--          <div style="margin: 20px 0; font-size: 16px; color: black">远航财税发票服务</div>-->
<!--          <div class="line_dashed"></div>-->

<!--          <div style="margin: 15px 15px 15px 0">-->
<!--            <el-form ref="form" label-width="80px">-->
<!--              <el-form-item label="商户名称:">-->
<!--                <span style="margin-left: 20px">{{ openInvoiceData.orgName }}</span>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="订单编号:">-->
<!--                <span style="margin-left: 20px">{{ openInvoiceData.sheetCode }}</span>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="项目名称:">-->
<!--                <span style="margin-left: 20px">{{ openInvoiceData.tmplName || '&#45;&#45;' }}</span>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="消费金额:">-->
<!--                <span style="margin-left: 20px">{{ openInvoiceData.sumAmount }}</span>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="创建时间:">-->
<!--                <span style="margin-left: 20px">{{ openInvoiceData.created }}</span>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="有效日期:">-->
<!--                <span style="margin-left: 20px">{{ openInvoiceData.expiredTime || '&#45;&#45;'}}</span>-->
<!--              </el-form-item>-->
<!--            </el-form>-->
<!--          </div>-->
<!--          <div class="line_dashed"></div>-->
<!--          <div style="margin: 15px 0; font-size: 16px; color: black; text-align:center">-->
<!--            <div style="text-align: center">扫码申请开票</div>-->
<!--            <div style="margin: 15px 0; ">-->
<!--              <img v-if="openInvoiceData.qrcodeImg" style="width:150px,height:150px" :src="openInvoiceData.qrcodeImg" alt="" />-->
<!--              <span v-else>暂无数据</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="line_dashed"></div>-->
<!--          <div style="margin: 20px 0 6px">微信搜索关注“金财数智”，接收、下载发票</div>-->
<!--          <div style="margin: 6px">请在有效期内扫码申请发票，过期无效</div>-->
<!--          <div style="margin: 6px 0 20px">客服电话: 4006080017</div>-->
<!--        </div>-->
<!--        <div style="margin-top: 10px; padding-bottom: 20px; text-align: center">-->
<!--          <el-button type="primary" @click="dialogVisibleYX = true">发送到邮箱</el-button>-->
<!--          <el-button type="primary" @click="print">打印</el-button>-->
<!--        </div>-->
      </el-dialog>
    </div>

    <InvoiceOpenDetail :show.sync="drawer" :invoice="openInvoiceData"></InvoiceOpenDetail>
  </div>
</template>

<script>
import PHeaderVue from '@/components/pdt/PHeader.vue';
import PSelectVue from '@/components/pdt/PSelect.vue';
import { changeMethods, dateOption, pagingMethods, tableStyle } from '@/util/mixins';
import OrganizationSelect from '@/components/widgets/OrganizationSelect.vue';
import { invoiceApplyList, getTicketNetworkPrint } from '@/service/quick-invoice';
import { updateQuickSumAmount, quickInvoiceModify, emailSend } from '@/service/quick-invoice';
import InvoiceOpenDetail from './InvoiceOpenDetail.vue';
import ElectronicTicketPrint from "@/views/invoice-open/quick-invoice/electronicTicketPrint.vue";
export default {
  components: { OrganizationSelect, PSelectVue, PHeaderVue, InvoiceOpenDetail, ElectronicTicketPrint },
  mixins: [tableStyle, pagingMethods, dateOption, changeMethods],
  data() {
    return {
      selectForm: {
        disposeStatus: '',
        invoiceRemark: '',
        created: '',
        sheetCode: '',
        remark: ''
      },
      applyParams: {
        disposeStatus: 'WCL',
        page: 1,
        size: 10,
        operSource: 'CYXCX',
        qrcodeType: 'AZZ',
        dateRange: ''
      },
      recordsLoading: false,
      sumAmount: '',
      dialogVisibleJE: false,
      dialogVisibleZF: false,
      dialogVisibleYX: false,
      dialogVisibleYL: false,
      drawer: false,
      receiveEmail: '',
      openInvoiceData: {},
      records: [],
      paging: {
        current: 1,
        size: 10,
        total: 0,
        searchCount: true,
        pages: 1
      },
      stateList: [
        { label: '全部', value: '' },
        { label: '未处理', value: 'WCL' },
        { label: '已处理', value: 'YCL' },
        { label: '未作废', value: 'YZF' }
      ],
      selections: [],
      pageSizes: [10, 30, 50, 100]
    };
  },
  created() {
    this.invoiceApplyList();
  },
  watch: {},
  methods: {
    async invoiceApplyList() {
      this.recordsLoading = true;
      let res = await invoiceApplyList(this.applyParams);
      if (res.success) {
        this.paging.total = res.data.total;
        this.records = res.data.records;
      }
      this.recordsLoading = false;
    },
    //表单重置
    reset() {
      this.selectForm = {
        disposeStatus: '',
        invoiceRemark: '',
        created: '',
        sheetCode: '',
        remark: ''
      };
    },
    fmtTableColumn(row, column) {
      switch (column.property) {
        case 'scene':
          if (row[column.property] === 'DMXKP') {
            return '多明细开票';
          } else if (row[column.property] === 'KSKP') {
            return '快速开票';
          } else {
            return '--';
          }
        case 'billingType':
          return this.handleValueToLabel('BillingType', row[column.property]);
        case 'invoiceStatus':
          if (row[column.property] === 'WCL') {
            return '未处理';
          } else if (row[column.property] === 'YCL') {
            return '已处理';
          } else if (row[column.property] === 'YZF') {
            return '未作废';
          } else {
            return '--';
          }
      }
    },
    handleSelection(value) {
      this.selections = value;
    },
    // 切换分页条数
    handleSizeChange(val) {
      this.applyParams.size = val;
      this.invoiceApplyList();
    },

    handleCurrentChange(val) {
      this.applyParams.page = val;
      this.invoiceApplyList();
    },
    // 查看二维码
    async handelrQrCode(row) {
      this.dialogVisibleYL = true;
      this.openInvoiceData = row;
      console.log(row);
    },
    details(row) {
      this.openInvoiceData = row;
      console.log(this.openInvoiceData, 'this.openInvoiceData');
      this.drawer = true;
    },
    confirmDel(row) {
      this.$confirm('此操作将删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { success } = await rpaDelete(row.id);
        if (success) {
          this.toast('删除成功', 'success');
          await this.invoiceApplyList();
        }
      });
    },
    handleTableItemMenu(type, item) {
      this.openInvoiceData = item;
      if (type == '1') {
        this.dialogVisibleJE = true;
        this.sumAmount = item.sumAmount;
      } else if (type == '2') {
        this.dialogVisibleYX = true;
      } else if (type == '3') {
        this.dialogVisibleZF = true;
      } else {
        // this.confirmDel()
      }
    },
    amendAffirm() {
      if (!this.sumAmount || this.sumAmount <= 0) {
        return this.toast('开票金额不能为空,且不能为0', 'warning');
      }
      const amountReg = /^\d+(\.\d{1,2})?$/;
      if (!amountReg.test(this.sumAmount)) {
        return this.toast('金额格式错误', 'warning');
      }
      updateQuickSumAmount({ id: this.openInvoiceData.id, sumAmount: this.sumAmount }).then((res) => {
        if (res.success) {
          this.dialogVisibleJE = false;
          this.toast('修改成功', 'success');
          this.openInvoiceData.sumAmount = this.sumAmount;
          this.sumAmount = '';
          this.invoiceApplyList();
          return;
        }
      });
    },
    async affirmZF() {
      const { success } = await quickInvoiceModify({ id: this.openInvoiceData.id, disposeStatus: 'YZF' });
      if (success) {
        this.toast('作废成功', 'success');
        this.dialogVisibleZF = false;
        this.invoiceApplyList();
      }
    },
    async affirmYX() {
      if (!this.receiveEmail) {
        return this.toast('邮箱不能为空', 'warning');
      }
      const emailReg = /[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
      if (!emailReg.test(this.receiveEmail)) {
        return this.toast('邮箱格式错误', 'warning');
      }
      const { success } = await emailSend({ requestId: this.openInvoiceData.id, receiveEmail: this.receiveEmail });
      debugger
      if (success){
        this.toast('发送成功', 'success');
      }
      this.dialogVisibleYX = false;
      this.invoiceApplyList();
    },
    async print() {
      let { success } = await getTicketNetworkPrint({ id: this.openInvoiceData.id });
      if (success) {
        this.toast('打印成功', 'success');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.edit-select {
  .edit-select-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px 0 20px;
  }

  .edit-select-item:first-child {
    margin-bottom: 50px;
  }

  .edit-select-item:last-child {
    margin-top: 20px;
  }

  .edit-select-foot {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: flex-end;
  }
}
.consumption_receipt_box {
  margin: 0px 20px 20px 20px;
  border: 1px solid #e9e9e9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #909399;
  ::v-deep .el-dialog__body {
    margin-top: 20px;
  }
}
.line {
  border-bottom: 1px solid #d4d3d1;
  width: 90%;
  height: 1px;
}
.line_dashed {
  border-bottom: 2px dashed #d4d3d1;
  width: 90%;
  height: 1px;
  // border-style: dashed ;
}
.invoice-dialog {
  div {
    margin-bottom: 12px;
  }

  span {
    width: 240px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 20px;
  }

  .el-image {
    width: 200px;
    height: 200px;
  }
}

.content-main {
  min-height: calc(100% - 170px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::v-deep .el-button {
    padding: 0;
  }
}

.append-btn {
  width: 135px;
}

.operate-button {
  display: flex;
  justify-content: center;

  .el-button {
    padding: 0;
    margin-right: 10px;
  }
}

.p-header {
  background-color: #fff;
  // margin-bottom: 20px;
}

.p-select {
  padding: 24px;

  ::v-deep .el-form-item__label {
    text-align: right;
  }
}

.p-content {
  // margin-top: 20px;

  ::v-deep .el-checkbox__label {
    display: none;
  }

  .table-btn {
    padding: 0 !important;
  }
}

.view {
  position: absolute;
  top: 10px;
  left: 88px;

  .password_img {
    width: 16px;
    height: 16px;
    margin-top: 8px;
  }
}

.verification_login {
  ::v-deep .el-dialog__body {
    padding: 0 0 0 0;
  }

  ::v-deep .el-input .el-input__clear {
    color: #00a0ff;
  }

  .el-icon-view {
    position: absolute;
    top: 10px;
    right: 12vh;
  }

  .password_img {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 8px;
    right: 12vh;
  }

  ::v-deep .el-form-item__content {
    position: relative;
  }
}

.app_qr_code {
  ::v-deep .el-dialog__header {
    padding: 20px 20px 0px;
  }
}

.add_amend_style {
  ::v-deep .el-dialog .el-dialog__body {
    padding: 10px 0 0;
  }
}
.add_num_account {
  ::v-deep .el-dialog .el-dialog__body {
    padding: 10px 0 0 0;
    text-align: left;
  }
  ::v-deep .el-form-item__error {
    top: 30px;
  }
}
</style>

<style lang="scss">
.electronic_receipt {
  .el-dialog .el-dialog__body {
    padding: 0px 0px 0px 0px;
  }
}
</style>
