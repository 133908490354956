<template>
  <div class="p-select">
    <!-- 筛选条件 -->
    <el-form v-if="repaint" class="p-select-form" :model="model" :ref="refName" :label-width="labelWidth"
             :rules="rules" :label-position="position">
      <div class="select-item" v-if="status || i == '1'" v-for="i in limit" :key="i">
        <slot :name="i"></slot>
      </div>
    </el-form>
    <!-- 筛选按钮 -->
    <div class="p-select-button">
      <select-button :show-status="showStatus"
                     @list-close="handleListClose"
                     @list-show="handleListShow"
                     @select="handleSelect"
                     @reset="handleReset">
      </select-button>
    </div>
  </div>
</template>

<script>
import SelectButton from '../buttons/SelectButton'

export default {
  name: 'PSelect',
  components: { SelectButton },
  props: {
    position: {
      type: String,
      default: 'right'
    },
    rules: {
      type: Object,
      default: () => {
      }
    },
    refName: {
      type: String,
      default: 'selectForm'
    },
    limit: {
      type: Number,
      default: 3
    },
    labelWidth: {
      type: String,
      default: '100px'
    },
    model: {
      type: Object,
      default: () => {
      }
    },
    condition: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    showStatus () {
      return this.limit > 1
    }
  },
  data () {
    return {
      selections: [],
      repaint: true,
      status: false
    }
  },
  methods: {
    handleListClose () {
      this.repaint = false
      this.$nextTick(() => {
        this.status = false
        this.repaint = true
      })
    },
    handleListShow () {
      this.repaint = false
      this.$nextTick(() => {
        this.status = true
        this.repaint = true
      })
    },
    handleSelect () {
      this.$emit('select')
    },
    handleReset () {
      this.$refs[`${this.refName}`].resetFields()
      this.$emit('reset')
    },
  }
}
</script>

<style lang="scss" scoped>
  .p-select {
    display: flex;
    flex-direction: row;
    padding: 0 24px 24px;
    align-items: flex-end;
    justify-content: flex-start;
  }

  .p-select-form {
    width: 80%;
    display: flex;
    flex-direction: column;

    .select-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .select-item:not(:first-child) {
      margin-top: 24px;
    }

    .el-form-item {
      display: flex;
      flex-direction: row;
    }
  }

  .p-select-button {
    flex: auto;
    margin-top: 0;
    display: flex;
    margin-left: 10px;
    flex-direction: row;
    justify-content: flex-end;
  }
</style>