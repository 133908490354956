<template>
    <div class="pdt-header" :style="headerBorder">
        <span :class="headerTitle">{{title}}</span>
        <div class="header-items">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PHeader",
        props: {
            title: {
                type: String,
                default: ""
            },
            spacer: {
                type: Boolean,
                default: false
            },
            border: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            headerTitle() {
                return this.spacer ? "spacer-title" : "title";
            },
            headerBorder() {
                return this.border ? "border-bottom: 1px solid #e9e9e9;" : "border-bottom: none";
            }
        }
    }
</script>

<style lang="scss">
    .pdt-header {
        display: flex;
        color: #ffffff;
        font-size: 16px;
        padding: 16px 24px;
        flex-direction: row;
        justify-content: space-between;
        background-color: #55a5f5;
        .spacer-title:before {
            content: "|";
            font-size: 16px;
            margin-right: 10px;
            display: inline-block;
            background-color: #ffffff;
        }

        .title {
            height: 32px;
            color: #666666;
            font-size: 16px;
            line-height: 32px;
        }
    }

    .header-items {
        display: inline-flex;

        .span {
            color: #3d94ff;
            font-weight: 500;
            line-height: 22px;
            margin-right: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
        }

        .img {
            width: 20px;
            height: 20px;
            margin-right: 4px;
            vertical-align: sub;
        }
    }

    .header-items .span:hover {
        cursor: pointer;
        text-decoration: underline;
    }
</style>